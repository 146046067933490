import React from "react";
import useWindowSize from "@components/_hooks/useWindowSize";
import dynamic from "next/dynamic";
import useUserJourney from "@components/_hooks/useUserJourney";
import useVAT from "@components/_hooks/useVAT";
import useUserInit from "@components/_hooks/InitHooks/useUserInit";
import { getBrands } from "@components/config/helpers";
import useActivityLogs from "@components/_hooks/CRMHooks/useActivityLogs";

const Header = dynamic(() => import('./Header'));
const HeaderMobile = dynamic(() => import('./HeaderMobile/HeaderMobile'));
const HeaderLoggedInBanner = dynamic(() => import("./HeaderLoggedInBanner"), {ssr: false});

const HeaderLayout = ({utm_data, route}) => {
    // const template_settings = useSelector(state => state.globalReducer.template_settings);
    const {isMobile} = useWindowSize();
    
    useUserJourney(utm_data);
    useActivityLogs();
    useVAT();
    useUserInit();
    // const layout = template_settings.layout ? template_settings.layout : "wide";

    return (
        <>
            {getBrands(['EH']) && (
                <HeaderLoggedInBanner />
            )}
            {!isMobile ? <Header /> : <HeaderMobile route={route} />}
        </>
    )
}

export default React.memo(HeaderLayout)