import { useState } from "react";
import { getBaseRequest, postBaseRequest } from "../config/axiosClient";
import { handleFetch } from "../config/helpers";
import useAlert from "./useAlert";


const useFetch = (showSuccessAlert = true) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(false);
    const {topBottomLeftAlert, customAlert, topMiddleAlert} = useAlert();

    const get = async (url) => {
        setLoading(true);
        const req = await handleFetch(getBaseRequest(url));
        setResponse(req);
        if(req.status == 'success'){
            setData(req.data);
            setError(false);
            if(showSuccessAlert){
                topMiddleAlert('SUCCESS', req.message);
            }
        } else if(req.status == 'warning'){
            topMiddleAlert('WARNING', req.message);
            setError(req.message);
        } else if(req.status == 'failed'){
            setError(req.message);
            topMiddleAlert('ERROR', req.message);
        } else {
            setError(req.message);
            topMiddleAlert('ERROR', req.message);
        }
        setLoading(false);
        return req;
    }

    const post = async (url, data, showAlert = true) => {
        setLoading(true);
        setResponse(null);
        const req = await handleFetch(postBaseRequest(url, data));
        setResponse(req);
        if(req.status == 'success'){
            setData(req.data);
            setError(false);
            if(showAlert){
                topMiddleAlert('SUCCESS', req.message);
            }
        } else if(req.status == 'warning'){
            setError(req.message);
            topMiddleAlert('WARNING', req.message);
        } else if(req.status == 'failed'){
            setError(req.message);
            topMiddleAlert('ERROR', req.message);
        } else {
            setError(req.message);
            topMiddleAlert('ERROR', req.message);
        }
        setLoading(false);
        return req;
    }

    return {
        get,
        post,
        data, response, loading, error, setLoading, setData
    }
}

export default useFetch;