import CacheFactory from '@components/Factories/CacheFactory';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useAuth from '../useAuth';
import useFetch from '../useFetch';

const useActivityLogs = () => {

    const user_reducer = useSelector(state => state.userReducer);
    const {isAgent, getUser, isAuthenticated} = useAuth();
    const [lastUrl, setLastUrl] = useState(null);
    
    // Check session
    const agent = isAgent();
    const user = getUser();
    const router = useRouter();
    const {post} = useFetch();

    // Check for flag
    const check_journey = user?.check_journey || false;
    
    
    let debounceTimeout = null;

    useEffect(() => {
        if (!agent || !isAuthenticated || !check_journey) return;
        const url = window.location.href;

        if(lastUrl == url || CacheFactory.has('latestUrl') && CacheFactory.get('latestUrl') == url){
            return;
        }

        const logPageVisit = () => {
            if (debounceTimeout) clearTimeout(debounceTimeout);

            debounceTimeout = setTimeout(async () => {
                try {
                    setLastUrl(url);
                    CacheFactory.put("latestUrl", url);

                    await post("agent/log-event", {
                        user_id: user.user_id,
                        url: url,
                        ip: user_reducer.ip,
                        user_agent: user_reducer.user_agent,
                    }, false);
                } catch (error) {
                    console.error("Error logging page visit:", error);
                }
            }, 2500);
        };

        logPageVisit();

        const handleRouteChange = () => logPageVisit();
        router.events.on("routeChangeComplete", handleRouteChange);

        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
            if (debounceTimeout) clearTimeout(debounceTimeout);
        };
    }, [router.asPath, user, agent]);

}

export default useActivityLogs;